/**
 * NOTE: We do not have a proper alias for $env, this means that this file
 * cannot be used outside of the SvelteKit context.
 *
 * The main implication is that you cannot import this file to be used in Playwright tests.
 *
 * To reference configuration variables in Playwright tests,
 * use the file `src/app.config.test.ts` instead.
 *
 * This is not ideal because src/app.config.test.ts is basically a duplicate of this file.
 *
 * This issue should be resolved over time, here are some tickets to monitor:
 * - https://github.com/microsoft/playwright/issues/19411
 * - https://github.com/microsoft/playwright/issues/18825
 * - https://github.com/microsoft/playwright/issues/18465
 * - https://github.com/sveltejs/kit/issues/1485
 *
 * Once this issue is resolved, you can uninstall the `dotenv` package.
 *
 * @author  William Hua
 * @date    01/04/2023
 */
import {
	PUBLIC_BASE_URL,
	PUBLIC_LONG_TASKS_BASE_URL,
	PUBLIC_PRODUCTION_BASE_URL,
	PUBLIC_S3_CDN_URL,
	PUBLIC_SENTRY_DSN,
	PUBLIC_ENVIRONMENT,
	PUBLIC_META_EVENTUS_PAGE_ID,
	PUBLIC_GOOGLE_PLACES_API_KEY,
	PUBLIC_MARKETING_URL,
	PUBLIC_PRODUCT_NAME,
	PUBLIC_COMPANY_NAME,
	PUBLIC_COMPANY_ADDRESS,
	PUBLIC_CRONOFY_CLIENT_ID,
	PUBLIC_CRONOFY_DATA_CENTER,
	PUBLIC_CRONOFY_SMART_INVITE_ORGANIZER_EMAIL,
	PUBLIC_STRIPE_PUBLISHABLE_KEY,
	PUBLIC_WORKOS_CLIENT_ID,
} from '$env/static/public';
export const appConfig = {
	baseUrl: PUBLIC_BASE_URL,
	cronofyAvailabilityRuleId: 'default',
	cronofyHardcodedDefaultInterviewLengthMinutes: 30,
	cronofyClientId: PUBLIC_CRONOFY_CLIENT_ID,
	cronofyDataCenter: PUBLIC_CRONOFY_DATA_CENTER,
	cronofySmartInviteOrganizerEmail: PUBLIC_CRONOFY_SMART_INVITE_ORGANIZER_EMAIL,
	defaultOrganizationPrimaryColor: '#3b5cde',
	freeTrialCreditsInDollars: 25,
	longTasksBaseUrl: PUBLIC_LONG_TASKS_BASE_URL,
	s3CdnUrl: PUBLIC_S3_CDN_URL,
	sentryDsn: PUBLIC_SENTRY_DSN,
	environment: PUBLIC_ENVIRONMENT,
	metaEventusPageId: PUBLIC_META_EVENTUS_PAGE_ID,
	googlePlacesApiKey: PUBLIC_GOOGLE_PLACES_API_KEY,
	privacyPolicyUrl: `${PUBLIC_PRODUCTION_BASE_URL}/privacy`,
	productionBaseUrl: PUBLIC_PRODUCTION_BASE_URL,
	tosUrl: `${PUBLIC_MARKETING_URL}/tos`,
	marketingUrl: PUBLIC_MARKETING_URL,
	productName: PUBLIC_PRODUCT_NAME,
	companyName: PUBLIC_COMPANY_NAME,
	companyAddress: PUBLIC_COMPANY_ADDRESS,
	// Hardcoded recommended budget per day. In the future it should be dynamically calculated based on the job's requirements
	recommendedBudget: '12', 
	supportEmail: 'support@hireline.com',
	stripePublishableKey: PUBLIC_STRIPE_PUBLISHABLE_KEY,
	workosClientId: PUBLIC_WORKOS_CLIENT_ID,
};
